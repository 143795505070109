<template>
    <v-dialog :disabled="disabled" persistent v-model="dialog" width="50%">
        <template v-slot:activator="{ on: dialog, attrs }">
            <v-btn v-bind="attrs" v-on="{ ...dialog }" :disabled="disabled" id="open-thumbnail-modal" color="primary" e2e-space-upload>
                <v-icon left>mdi-upload</v-icon>
                Upload
            </v-btn>
        </template>
        <v-card>
            <v-card-title>File upload</v-card-title>
            <v-card-text>
                <v-tabs v-model="tab">
                    <v-tab>Local device</v-tab>
                    <v-tab>Web Address (URL)</v-tab>
                </v-tabs>

                <v-tabs-items v-model="tab">
                    <v-tab-item>
                        <v-card flat class="mt-4">
                            <v-card-text>
                                <uppy-upload :endpoint="endpoint" @uploadComplete="dialog = false" @uploading="uploading" :reset="dialog" />
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                    <v-tab-item>
                        <v-card flat class="mt-4">
                            <v-card-text>
                                <url-upload @uploadComplete="dialog = false" @uploading="uploading" :reset="dialog"></url-upload>
                            </v-card-text>
                        </v-card>
                    </v-tab-item>
                </v-tabs-items>
            </v-card-text>
            <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn text @click="dialog = false" :disabled="loading">Cancel</v-btn>
            </v-card-actions>
        </v-card>
    </v-dialog>
</template>

<script>
import eventBus from '@/components/EventBus'
const UppyUpload = () => import('@/components/UppyUpload.vue')
const UrlUpload = () => import('@/components/UrlUpload.vue')

export default {
    name: 'FileUpload',
    components: {
        UppyUpload,
        UrlUpload
    },
    props: {
        endpoint: {
            type: String,
            required: true
        },
        disabled: {
            type: Boolean,
            default: false
        }
    },
    data() {
        return {
            dialog: false,
            tab: null,
            loading: false
        }
    },
    created() {
        eventBus.$on('openFileUploadDialog', evt => {
            this.dialog = true
        })
    },
    methods: {
        uploading(value) {
            this.loading = value
        }
    }
}
</script>
